import { message } from "antd"
import { API } from "../config"

const prasukAuthService = {}

export const signUp = (user) => {
	return fetch(`${API}/auth/register`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then(async (response) => {
			let temp = await response.json()
			 if (response.status === 201) {
				alert("Account registred successfully please verify your email");
				window.location = '/auth/login';
			}
			else{
				message.error(temp.message)
			}
		})
		.catch((err) => {
			console.log(err, err.status)
		})
}

// prasukAuthService.signin = (user) => {
//   return fetch(`${API}/signin`, {
//     method: "POST",
//     headers: {
//       Accept: "application/json",
//       "Content-Type": "application/json",
//     },
//     body: JSON.stringify(user),
//   })
//     .then((response) => {
//       return response.json();
//     })
//     .catch((err) => {
//       console.log(err);
//     });
// };

prasukAuthService.signin = (user) => {
	return fetch(`${API}/verifyotp`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then((response) => {
			if (response.status === 401) {
				alert("OTP doesn't match")
			} else if (response.status === 403) {
				alert("Access denied! Contact Tech team")
			}
			// console.log("response", response, response.status)
			else if (response.status === 200) {
				return response.json()
			}
		})
		.catch((err) => {
			console.log(err, err.status)
		})
}

export const buyerSignin = (user) => {
	return fetch(`${API}/auth/login`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
	.then(async (response) => {
		if(response.status==200){
			
			let data = await response.json()
			return {
				...data,
				ok: true
			}
		}
		else{
			return response.json()

		}
	})
	.catch((err) => {
		console.log("error", err)
	})
}
export const verifyEmail = (user) => {
	return fetch(`${API}/auth/verify-account-email`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
	.then(async (response) => {
		if(response.status==200){
			
			let data = await response.json()
			return {
				...data,
				ok: true
			}
		}
		else{
			return response.json()

		}
	})
		
		.catch((err) => {
			console.log(err.status, err)
		})
}
export const forgotPassword = (user) => {
	return fetch(`${API}/auth/forgot-password`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then((response) => {
			console.log("resp", response.status, response)
			// return response.json()
		})
		
		.catch((err) => {
			console.log(err.status, err)
		})
}
export const setNewPassword = (user) => {
	return fetch(`${API}/auth/set-new-password`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
		},
		body: JSON.stringify(user),
	})
		.then(async (response) => {
			console.log("resp", response.status, response)
			if(response.status == 200)
			{
				let resData = await response.json();
				return { ...resData, ok: true};
			}
			else{
				return response.json();
			}
		})
		
		.catch((err) => {
			console.log(err.status, err)
		})
}
prasukAuthService.authenticate = (data, next) => {
	if (typeof window !== "undefined") {
		localStorage.setItem("jwt", JSON.stringify(data))
		next()
	}
}

prasukAuthService.signout = (next) => {
	if (typeof window !== undefined) {
		localStorage.removeItem("jwt")
		next()
		return fetch(`${API}/signout`, {
			method: "GET",
		})
			.then((response) => {
				// console.log("signout", response)
			})
			.catch((err) => console.log(err))
	}
}

prasukAuthService.isAuthenticated = () => {
	if (typeof window == "undefined") {
		return false
	}

	if (localStorage.getItem("jwt")) {
		return JSON.parse(localStorage.getItem("jwt"))
	} else {
		return false
	}
}

export default prasukAuthService
