import { Order_Id_To_Allocate } from  '../constants/Orderdetails'

const initState = {
    orderId: [],
}

const orderIds = (state = initState, action) => {
	switch (action.type) {
		case Order_Id_To_Allocate:
			return {
				...state,
				orderId: action.data
			}
            break;
		default:
			return state;
	}
}

export default orderIds