import { AppstoreOutlined, ShoppingCartOutlined, HomeOutlined, LogoutOutlined, UserOutlined , TagOutlined, ContainerOutlined} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import { isAuthenticated } from "../../src/views/app-views/apps/e-commerce/auth";

let { user, token } = isAuthenticated();

const appsNavTree = [
  // {
  //   key: "apps",
  //   path: `${APP_PREFIX_PATH}/apps`,
  //   title: " ",
  //   icon: AppstoreOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //           {
  //       key: "apps-ecommerce",
  //       path: `${APP_PREFIX_PATH}/apps/ecommerce`,
  //       title: "Order From",
  //       icon: ShoppingCartOutlined,
  //       breadcrumb: false,
  //       submenu: [
  // {
  //   key: "apps-all-cc",
  //   path: `${APP_PREFIX_PATH}/apps/ecommerce/all-collection-center`,
  //   title: "Collection Center",
  //   icon: HomeOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "apps-all-cc-products",
  //   path: `${APP_PREFIX_PATH}/apps/ecommerce/AllProducts`,
  //   title: "Products",
  //   icon: TagOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // }]}
  // ]},
  {
    key: "apps-all-cc-products",
    path: `${APP_PREFIX_PATH}/apps/ecommerce/AllProducts`,
    title: "Products",
    icon: TagOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "apps-ecommerce-orders",
    path: `${APP_PREFIX_PATH}/apps/ecommerce/orders`,
    title: "Orders",
    icon: ContainerOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "apps-product-cart",
    path: `${APP_PREFIX_PATH}/apps/ecommerce/Cart`,
    title: "Cart",
    icon: ShoppingCartOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "apps-buyer-profile",
    path: `${APP_PREFIX_PATH}/apps/ecommerce/profile`,
    title: "Profile",
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "apps-cc-details",
  //   path: `${APP_PREFIX_PATH}/apps/ecommerce/cc-details`,
  //   title: "Cc Details",
  //   icon: "",
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "apps-profile-signout",
    path: `${APP_PREFIX_PATH}/apps/ecommerce/Signout`,
    title: "Signout",
    icon: LogoutOutlined,
    breadcrumb: false,
    submenu: [],
  },
];

// side navigation for role 2 admin
const adminRoleTowNavTree = [
  {
    key: "apps",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "sidenav.apps",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "apps-ecommerce",
        path: `${APP_PREFIX_PATH}/apps/ecommerce`,
        title: "sidenav.apps.ecommerce",
        icon: ShoppingCartOutlined,
        breadcrumb: false,
        submenu: [
          {
            key: "apps-ecommerce-receivng-request",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/receiving-request-list`,
            title: "Receiving Request ",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "apps-ecommerce-farmerList",
            path: `${APP_PREFIX_PATH}/apps/ecommerce/cc/vendor-list`,
            title: "Farmer List",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

let displayNavTree = user && user.role == 2 ? adminRoleTowNavTree : appsNavTree;

const navigationConfig = [...displayNavTree];

export default navigationConfig;
